








































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import InputPhoneInternational from '@/app/ui/components/InputPhoneInternational/index.vue'
import TimePicker from '@/app/ui/components/TimePicker/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import controller, {
  IShuttleForm,
} from '@/app/ui/controllers/ShuttleController'
import TrashIcon from '@/app/ui/assets/ics_f_trash.vue'
import PlusIcon from '@/app/ui/assets/ics_f_plus_circle.vue'
import SearchIcon from '@/app/ui/assets/ics_o_search.vue'
import { validationMixin } from 'vuelidate'
import { Validations } from 'vuelidate-property-decorators'
import {
  helpers,
  maxLength,
  minLength,
  required,
} from 'vuelidate/lib/validators'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'
import { Utils } from '@/app/infrastructures/misc'
import manageCaptainController from '@/app/ui/controllers/ManageCaptainController'
import { ManageCaptain } from '@/domain/entities/ManageCaptain'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import {
  IHeaderCell,
  IOptions,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/shuttleManagement'
import { ManageCourier } from '@/domain/entities/ManageCourier'
import ModalConfirm from '../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../components/Modals/ModalSuccess/index.vue'
import { CreateShuttleRequest } from '@/data/payload/api/ShuttleManagementRequest'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import {
  CourierSortFields,
  CourierSortType,
} from '@/app/infrastructures/misc/Constants/cancelValidation'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import { ShuttleDetail } from '@/domain/entities/Shuttle'

interface IValidation {
  [key: string]: unknown
}

@Component({
  mixins: [validationMixin],
  components: {
    TextInput,
    Button,
    InputPhoneInternational,
    TimePicker,
    DropdownSelect,
    DataTableV2,
    ModalConfirm,
    ModalSuccess,
    LoadingOverlay,
    TrashIcon,
    PlusIcon,
    SearchIcon,
    IconWarningTriangle,
    AscendingIcon,
  },
})
export default class ShuttleForm extends Vue {
  controller = controller
  manageCaptainController = manageCaptainController

  hourOptions = Array.from({ length: 24 }, (_n, index) => index)
  minuteOptions = Array.from({ length: 60 }, (_n, index) => index)

  captainOptions: IOptions[] = []

  enumSelectedSort = CourierSortType
  enumSortFields = CourierSortFields

  selectedSort: CourierSortType | null = null

  headers = [
    this.headerCellMapper('No', '60px'),
    this.headerCellMapper('Kurir ID', '100px'),
    this.headerCellMapper('Nama Kurir', '158px'),
    this.headerCellMapper('No. Handphone', '182px'),
    this.headerCellMapper('Jenis Kurir', '120px'),
    this.headerCellMapper('POS', '250px'),
    this.headerCellMapper('3LC', '80px'),
    this.headerCellMapper('Atur', '120px'),
  ]

  isModalConfirmVisible = false
  isModalSuccessVisible = false

  created(): void {
    const shuttleId = <string>this.$route.params.shuttleId

    if (this.isEditPage && !this.controller.isEditAddCourier) {
      controller.getShuttle(shuttleId)
    }

    this.controller.setIsEditAddCourier(false)
  }

  get form(): IShuttleForm {
    return this.controller.shuttleForm
  }

  set form(data: IShuttleForm) {
    this.controller.shuttleForm = {
      ...data,
    }
  }

  get shuttleDetailData(): ShuttleDetail {
    return this.controller.shuttleDetailData
  }

  get sortedCourierShuttleList(): ManageCourier[] {
    const tempCourierList = [...this.form.shuttleCourier]
    if (this.selectedSort) {
      if (
        this.selectedSort.split('-')[0] === this.enumSortFields.COURIER_NAME
      ) {
        if (this.selectedSort.split('-')[1] === 'asc') {
          return tempCourierList.sort(
            (a, b) => <number>a.fullName?.localeCompare(<string>b.fullName)
          )
        } else {
          return tempCourierList.sort(
            (a, b) => <number>b.fullName?.localeCompare(<string>a.fullName)
          )
        }
      } else {
        if (this.selectedSort.split('-')[1] === 'asc') {
          return tempCourierList.sort(
            (a, b) =>
              <number>a.phoneNumber?.localeCompare(<string>b.phoneNumber)
          )
        } else {
          return tempCourierList.sort(
            (a, b) =>
              <number>b.phoneNumber?.localeCompare(<string>a.phoneNumber)
          )
        }
      }
    }

    return tempCourierList
  }

  get courierShuttleList(): ITableCell[][] | [] {
    return this.sortedCourierShuttleList.map((courier, index) => [
      this.tableCellMapper(index + 1, '60px'),
      this.tableCellMapper(<number>courier.courierId, '100px'),
      this.tableCellMapper(<string>courier.fullName, '152px'),
      this.tableCellMapper(<string>courier.phoneNumber, '182px'),
      this.tableCellMapper(<string>courier.courierTypeValid, '120px'),
      this.tableCellMapper(<string>courier.partnerName, '250px'),
      this.tableCellMapper(<string>courier.origin, '80px'),
      this.tableCellMapper(<number>courier.courierId, '120px'),
    ])
  }

  get isEditPage(): boolean {
    return this.$route.name === 'ShuttleManagementEditForm'
  }

  @Validations()
  validations(): IValidation {
    return {
      form: {
        shuttleId: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
          isAlreadyUsed: () => {
            return !this.controller.isShuttleIdAlreadyUsed
          },
        },
        shuttleName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100),
        },
        shuttleAddress: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(500),
        },
        shuttleMapUrl: { required },
        shuttlePic: {
          $each: {
            picName: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(100),
            },
            picPhoneNumber: {
              required,
              minLength: minLength(8),
              maxLength: maxLength(15),
            },
          },
        },
        shuttleCycle: {
          $each: {
            startTime: {
              required,
            },
            endTime: {
              required,
              isMoreThanStartTime: function(
                val: Date,
                obj: { startTime: Date | null; endTime: Date | null }
              ) {
                if (val && obj.startTime) {
                  return val.getTime() > obj.startTime.getTime()
                }
                return true
              },
            },
          },
        },
      },
    }
  }

  public addPic(): void {
    this.form.shuttlePic.push({
      picName: '',
      picPhoneNumber: '',
      picPhoneCode: '+62',
    })
  }

  public addCycle(): void {
    this.form.shuttleCycle.push({
      id: new Date().getTime() + Math.random(),
      startTime: null,
      endTime: null,
    })
  }

  public deletePic(index: number): void {
    this.form.shuttlePic.splice(index, 1)
  }

  public deleteCycle(index: number): void {
    this.form.shuttleCycle.splice(index, 1)
  }

  public onSearchCaptain = Utils.debounce((keyword: string) => {
    this.manageCaptainController.getAll({
      status: 'ACTIVE',
      perPage: 100,
      keyword,
    })
  }, 500)

  public onDeleteShuttleCourier(courierId: number): void {
    this.form.shuttleCourier = this.form.shuttleCourier.filter(
      courier => courier.courierId !== courierId
    )
  }

  public submit(): void {
    const payload = new CreateShuttleRequest(
      this.form.shuttleId,
      this.form.shuttleName,
      this.form.shuttleAddress,
      this.form.shuttleMapUrl,
      <string>this.form.shuttleCaptain?.value,
      this.form.shuttleCycle,
      this.form.shuttleCourier,
      this.form.shuttlePic
    )

    if (this.isEditPage) {
      const shuttleId = <string>this.$route.params.shuttleId
      this.controller.updateShuttle({ payload, shuttleId })
    } else {
      this.controller.createShuttle(payload)
    }

    this.isModalConfirmVisible = false
  }

  public onTableSort(sortType: CourierSortFields): void {
    switch (sortType) {
      case CourierSortFields.COURIER_NAME:
        if (this.selectedSort === CourierSortType.COURIER_NAME_ASC) {
          this.selectedSort = CourierSortType.COURIER_NAME_DESC
        } else if (this.selectedSort === CourierSortType.COURIER_NAME_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = CourierSortType.COURIER_NAME_ASC
        }
        break
      case CourierSortFields.COURIER_PHONE_NUMBER:
        if (this.selectedSort === CourierSortType.COURIER_PHONE_NUMBER_ASC) {
          this.selectedSort = CourierSortType.COURIER_PHONE_NUMBER_DESC
        } else if (
          this.selectedSort === CourierSortType.COURIER_PHONE_NUMBER_DESC
        ) {
          this.selectedSort = null
        } else {
          this.selectedSort = CourierSortType.COURIER_PHONE_NUMBER_ASC
        }
        break
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        wordBreak: 'break-word',
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    isDefault = false,
    routes?: string
  ): ITableCell {
    return {
      value,
      isDefault,
      routes,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('form.shuttleId')
  onShuttleIdChanged(value: string): void {
    this.form.shuttleId = value.replace(/[^\w\s-]/g, '').replace(/\s+/g, '')
  }

  @Watch('manageCaptainController.dataManageCaptainList')
  onDataManageCaptainListChanged(data: ManageCaptain[]): void {
    this.captainOptions = data.map(captain => {
      return {
        label: `[${captain.courierId}] ${captain.fullname} (KVP) ${captain.phoneNumber}`,
        value: `${captain.courierId},${captain.courierCaptainId}`,
      }
    })
  }

  @Watch('controller.isSuccessSubmitShuttle')
  onIsSuccessSubmitShuttleChanged(data: boolean): void {
    if (data) {
      this.isModalSuccessVisible = true
      this.controller.setIsSuccessSubmitShuttle(false)
    }
  }

  @Watch('shuttleDetailData')
  onShuttleDetailDataChanged(data: ShuttleDetail): void {
    if (data.shuttleId) {
      this.form.shuttleAddress = <string>data.shuttleAddress
      this.form.shuttleCaptain = data.shuttleCourierCaptain?.courierId
        ? {
            label: `[${data.shuttleCourierCaptain.courierId}] ${data.shuttleCourierCaptain.fullName} (KVP) ${data.shuttleCourierCaptain.phoneNumber}`,
            value: `${data.shuttleCourierCaptain.courierId},${data.shuttleCourierCaptain.courierCaptainId}`,
          }
        : null
      this.form.shuttleCourier = <ManageCourier[]>data.shuttleCourier
      this.form.shuttleCycle = data.shuttleCycle?.length
        ? data.shuttleCycle.map(cycle => {
            return {
              id: new Date().getTime() + Math.random(),
              endTime: new Date(
                `${Utils.formatDateWithIDLocale(
                  new Date().toISOString(),
                  'YYYY-MM-DD'
                )} ${cycle.endAt}+07:00`
              ),
              startTime: new Date(
                `${Utils.formatDateWithIDLocale(
                  new Date().toISOString(),
                  'YYYY-MM-DD'
                )} ${cycle.startAt}+07:00`
              ),
            }
          })
        : []
      this.form.shuttleId = data.shuttleId
      this.form.shuttleMapUrl = <string>data.shuttleMapUrl
      this.form.shuttleName = <string>data.shuttleName
      this.form.shuttlePic = data.shuttlePic?.length
        ? data.shuttlePic.map(pic => {
            return {
              picPhoneCode: '+62',
              picName: <string>pic.picName,
              picPhoneNumber: <string>pic.picPhoneNumber,
            }
          })
        : []
      this.form.shuttleRegion = <string>data.shuttleRegion

      this.controller.setShuttleDetailData(new ShuttleDetail())
    }
  }
}
