function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"flex flex-col gap-6"},[_c('h1',{staticClass:"text-xl font-semibold text-gray-4"},[_vm._v(" "+_vm._s(_vm.isEditPage ? 'Edit' : 'Tambah')+" Shuttle ")]),_c('div',{staticClass:"flex flex-col gap-6"},[_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"label required"},[_vm._v("Shuttle ID")]),_c('div',[_c('TextInput',{attrs:{"placeholder":"Masukkan shuttle id","maxCount":100,"withCounter":"","isError":_vm.$v.form.shuttleId.$invalid && _vm.$v.form.shuttleId.$dirty},on:{"input":function () {
              _vm.controller.setIsShuttleIdAlreadyUsed(false)
            }},model:{value:(_vm.$v.form.shuttleId.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.shuttleId, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.shuttleId.$model"}}),(_vm.$v.form.shuttleId.$invalid && _vm.$v.form.shuttleId.$dirty)?_c('div',{staticClass:"flex flex-row items-center gap-2"},[_c('IconWarningTriangle'),_c('span',{staticClass:"text-sm text-red-1 mt-1"},[(!_vm.$v.form.shuttleId.required)?[_vm._v("Shuttle id harus diisi")]:_vm._e(),(!_vm.$v.form.shuttleId.isAlreadyUsed)?[_vm._v("Shuttle id sudah tersedia. Cek & coba lagi")]:_vm._e(),(
                !_vm.$v.form.shuttleId.minLength || !_vm.$v.form.shuttleId.maxLength
              )?[_vm._v("Shuttle id harus diantara 3 - 100 karakter")]:_vm._e()],2)],1):_vm._e()],1)]),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"label required"},[_vm._v("Nama Shuttle")]),_c('div',[_c('TextInput',{attrs:{"placeholder":"Masukkan nama shuttle","maxCount":100,"withCounter":"","isError":_vm.$v.form.shuttleName.$dirty && _vm.$v.form.shuttleName.$invalid},model:{value:(_vm.$v.form.shuttleName.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.shuttleName, "$model", $$v)},expression:"$v.form.shuttleName.$model"}}),(_vm.$v.form.shuttleName.$invalid && _vm.$v.form.shuttleName.$dirty)?_c('div',{staticClass:"flex flex-row items-center gap-2"},[_c('IconWarningTriangle'),_c('span',{staticClass:"text-sm text-red-1 mt-1"},[(!_vm.$v.form.shuttleName.required)?[_vm._v("Nama shuttle harus diisi")]:_vm._e(),(
                !_vm.$v.form.shuttleName.minLength ||
                  !_vm.$v.form.shuttleName.maxLength
              )?[_vm._v("Nama shuttle harus diantara 3 - 100 karakter")]:_vm._e()],2)],1):_vm._e()],1)]),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"label required"},[_vm._v("Alamat Shuttle")]),_c('div',[_c('TextInput',{attrs:{"placeholder":"Masukkan alamat shuttle","maxCount":500,"withCounter":"","isError":_vm.$v.form.shuttleAddress.$invalid && _vm.$v.form.shuttleAddress.$dirty},model:{value:(_vm.$v.form.shuttleAddress.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.shuttleAddress, "$model", $$v)},expression:"$v.form.shuttleAddress.$model"}}),(
            _vm.$v.form.shuttleAddress.$invalid && _vm.$v.form.shuttleAddress.$dirty
          )?_c('div',{staticClass:"flex flex-row items-center gap-2"},[_c('IconWarningTriangle'),_c('span',{staticClass:"text-sm text-red-1 mt-1"},[(!_vm.$v.form.shuttleAddress.required)?[_vm._v("Alamat shuttle harus diisi")]:_vm._e(),(
                !_vm.$v.form.shuttleAddress.maxLength ||
                  !_vm.$v.form.shuttleAddress.minLength
              )?[_vm._v("Alamat shuttle harus diantara 3 - 500 karakter")]:_vm._e()],2)],1):_vm._e()],1)]),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"label required"},[_vm._v("Link Google Maps Lokasi Shuttle")]),_c('div',[_c('TextInput',{attrs:{"placeholder":"Masukkan link Google Maps lokasi shuttle","isError":_vm.$v.form.shuttleMapUrl.$invalid && _vm.$v.form.shuttleMapUrl.$dirty},model:{value:(_vm.$v.form.shuttleMapUrl.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.shuttleMapUrl, "$model", $$v)},expression:"$v.form.shuttleMapUrl.$model"}}),(
            _vm.$v.form.shuttleMapUrl.$invalid && _vm.$v.form.shuttleMapUrl.$dirty
          )?_c('div',{staticClass:"flex flex-row items-center gap-2"},[_c('IconWarningTriangle'),_c('span',{staticClass:"text-sm text-red-1 mt-1"},[(!_vm.$v.form.shuttleMapUrl.required)?[_vm._v("Link Google Maps lokasi shuttle harus diisi")]:_vm._e()],2)],1):_vm._e()],1)]),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"label required"},[_vm._v("PIC")]),_c('div',{staticClass:"flex flex-col gap-5 border border-gray-2 p-5 rounded-lg"},[_vm._l((_vm.form.shuttlePic),function(pic,index){return _c('div',{key:index,staticClass:"flex flex-row gap-5"},[_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"label required"},[_vm._v("Nama PIC")]),_c('div',[_c('TextInput',{attrs:{"placeholder":"Masukkan nama PIC","withCounter":"","maxCount":100,"isError":_vm.$v.form.shuttlePic.$each[index].picName.$dirty &&
                    _vm.$v.form.shuttlePic.$each[index].picName.$invalid},model:{value:(_vm.$v.form.shuttlePic.$each[index].picName.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.shuttlePic.$each[index].picName, "$model", $$v)},expression:"$v.form.shuttlePic.$each[index].picName.$model"}}),(
                  _vm.$v.form.shuttlePic.$each[index].picName.$dirty &&
                    _vm.$v.form.shuttlePic.$each[index].picName.$invalid
                )?_c('div',{staticClass:"flex flex-row items-center gap-2"},[_c('IconWarningTriangle'),_c('span',{staticClass:"text-sm text-red-1 mt-1"},[(!_vm.$v.form.shuttlePic.$each[index].picName.required)?[_vm._v("Nama PIC harus diisi")]:_vm._e(),(
                      !_vm.$v.form.shuttlePic.$each[index].picName.maxLength ||
                        !_vm.$v.form.shuttlePic.$each[index].picName.minLength
                    )?[_vm._v("Nama PIC harus diantara 3 - 100 karakter")]:_vm._e()],2)],1):_vm._e()],1)]),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"label required"},[_vm._v("No. Handphone PIC")]),_c('div',{staticClass:"flex flex-row gap-5"},[_c('div',[_c('InputPhoneInternational',{attrs:{"placeholder":"817762421224","phoneCode":pic.picPhoneCode,"isError":_vm.$v.form.shuttlePic.$each[index].picPhoneNumber.$dirty &&
                      _vm.$v.form.shuttlePic.$each[index].picPhoneNumber.$invalid},on:{"phoneCodeChange":function (val) {
                      pic.picPhoneCode = val
                    }},model:{value:(
                    _vm.$v.form.shuttlePic.$each[index].picPhoneNumber.$model
                  ),callback:function ($$v) {_vm.$set(_vm.$v.form.shuttlePic.$each[index].picPhoneNumber, "$model", $$v)},expression:"\n                    $v.form.shuttlePic.$each[index].picPhoneNumber.$model\n                  "}}),(
                    _vm.$v.form.shuttlePic.$each[index].picPhoneNumber.$dirty &&
                      _vm.$v.form.shuttlePic.$each[index].picPhoneNumber.$invalid
                  )?_c('div',{staticClass:"flex flex-row items-center gap-2"},[_c('IconWarningTriangle'),_c('span',{staticClass:"text-sm text-red-1 mt-1"},[(
                        !_vm.$v.form.shuttlePic.$each[index].picPhoneNumber
                          .required
                      )?[_vm._v("No. handphone PIC harus diisi")]:_vm._e(),(
                        !_vm.$v.form.shuttlePic.$each[index].picPhoneNumber
                          .maxLength ||
                          !_vm.$v.form.shuttlePic.$each[index].picPhoneNumber
                            .minLength
                      )?[_vm._v("No. handphone PIC harus diantara 8 - 15 karakter")]:_vm._e()],2)],1):_vm._e()],1),_c('div',{staticClass:"w-20"},[(_vm.form.shuttlePic.length > 1)?_c('Button',{staticClass:"text-sm font-semibold flex flex-row gap-1",attrs:{"data-testid":("shuttle-form__delete-pic-" + index),"buttonStyle":"outline"},on:{"click":function () { return _vm.deletePic(index); }}},[_c('TrashIcon'),_vm._v(" Hapus ")],1):_vm._e()],1)])])])}),_c('Button',{staticClass:"flex flex-row gap-1 text-sm font-semibold w-52 justify-center",attrs:{"data-testid":"shuttle-form__add-pic-button","buttonStyle":"outline"},on:{"click":_vm.addPic}},[_c('PlusIcon'),_vm._v(" Tambah PIC ")],1)],2)]),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"label required"},[_vm._v("Shuttle Cycle")]),_c('div',{staticClass:"flex flex-col gap-5 border border-gray-2 p-5 rounded-lg"},[_vm._l((_vm.form.shuttleCycle),function(cycle,index){return _c('div',{key:index,staticClass:"flex flex-row gap-5"},[_c('div',{staticClass:"flex flex-col gap-2 w-64"},[_c('span',{staticClass:"label required"},[_vm._v("Waktu Mulai")]),_c('div',[_c('TimePicker',{attrs:{"isInputPositionY":false,"placeholder":"00:00 WIB","hourOptions":_vm.hourOptions,"minuteOptions":_vm.minuteOptions,"isError":_vm.$v.form.shuttleCycle.$each[index].startTime.$dirty &&
                    _vm.$v.form.shuttleCycle.$each[index].startTime.$invalid},model:{value:(_vm.$v.form.shuttleCycle.$each[index].startTime.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.shuttleCycle.$each[index].startTime, "$model", $$v)},expression:"$v.form.shuttleCycle.$each[index].startTime.$model"}}),(
                  _vm.$v.form.shuttleCycle.$each[index].startTime.$dirty &&
                    _vm.$v.form.shuttleCycle.$each[index].startTime.$invalid
                )?_c('div',{staticClass:"flex flex-row items-center gap-2"},[_c('span',{staticClass:"text-sm text-red-1 mt-1"},[(
                      !_vm.$v.form.shuttleCycle.$each[index].startTime.required
                    )?[_vm._v("Waktu mulai harus diisi")]:_vm._e()],2)]):_vm._e()],1)]),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"label required"},[_vm._v("Waktu Berakhir")]),_c('div',{staticClass:"flex flex-row gap-5"},[_c('div',{staticClass:"w-64"},[_c('TimePicker',{attrs:{"isInputPositionY":false,"placeholder":"13:00 WIB","hourOptions":_vm.hourOptions,"minuteOptions":_vm.minuteOptions,"isError":_vm.$v.form.shuttleCycle.$each[index].endTime.$dirty &&
                      _vm.$v.form.shuttleCycle.$each[index].endTime.$invalid},model:{value:(_vm.$v.form.shuttleCycle.$each[index].endTime.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.shuttleCycle.$each[index].endTime, "$model", $$v)},expression:"$v.form.shuttleCycle.$each[index].endTime.$model"}}),(
                    _vm.$v.form.shuttleCycle.$each[index].endTime.$dirty &&
                      _vm.$v.form.shuttleCycle.$each[index].endTime.$invalid
                  )?_c('div',{staticClass:"flex flex-row items-center gap-2"},[_c('span',{staticClass:"text-sm text-red-1 mt-1"},[(
                        !_vm.$v.form.shuttleCycle.$each[index].endTime.required
                      )?[_vm._v(" Waktu berakhir harus diisi ")]:(
                        !_vm.$v.form.shuttleCycle.$each[index].endTime
                          .isMoreThanStartTime
                      )?[_vm._v(" Waktu akhir harus lebih besar dari waktu mulai. Cek lagi ")]:_vm._e()],2)]):_vm._e()],1),(_vm.form.shuttleCycle.length > 1)?_c('Button',{staticClass:"flex flex-row gap-1 text-sm font-semibold h-10",attrs:{"data-testid":("shuttle-form__delete-cycle-" + index),"buttonStyle":"outline"},on:{"click":function () { return _vm.deleteCycle(index); }}},[_c('TrashIcon'),_vm._v(" Hapus ")],1):_vm._e()],1)])])}),_c('Button',{staticClass:"flex flex-row gap-1 text-sm font-semibold w-52 justify-center",attrs:{"data-testid":"shuttle-form__add-cycle-button","buttonStyle":"outline"},on:{"click":_vm.addCycle}},[_c('PlusIcon'),_vm._v(" Tambah Jadwal ")],1)],2)]),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"label"},[_vm._v("Pilih Captain Shuttle")]),_c('div',{staticClass:"flex flex-col gap-5 border border-gray-2 p-5 rounded-lg"},[_c('div',{staticClass:"relative"},[_c('DropdownSelect',{staticClass:"captain-dropdown",attrs:{"data-testid":"shuttle-form__captain-shuttle","placeholder":"Cari & Pilih nama kurir/nomor handphone","options":_vm.captainOptions},on:{"search":_vm.onSearchCaptain},model:{value:(_vm.form.shuttleCaptain),callback:function ($$v) {_vm.$set(_vm.form, "shuttleCaptain", $$v)},expression:"form.shuttleCaptain"}}),_c('div',{staticClass:"absolute right-3 top-2"},[_c('SearchIcon')],1)],1)])]),_c('div',{staticClass:"flex flex-col gap-2"},[_c('span',{staticClass:"label"},[_vm._v("Pilih Kurir Shuttle")]),_c('div',{staticClass:"flex flex-col gap-5 border border-gray-2 p-5 rounded-lg"},[(_vm.form.shuttleCourier.length)?_c('div',{staticClass:"shuttle-form-table"},[_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.courierShuttleList},scopedSlots:_vm._u([{key:"header-2",fn:function(ref){
                      var rest = objectWithoutProperties( ref, [] );
                      var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5 items-center cursor-pointer",attrs:{"data-testid":"shuttle-form__courier-name-sort"},on:{"click":function($event){return _vm.onTableSort(_vm.enumSortFields.COURIER_NAME)}}},[_c('span',{staticClass:"w-24 sort-field"},[_vm._v(_vm._s(data.data.title))]),_c('div',{staticClass:"flex flex-col table-sort"},[_c('div',{staticClass:"ascending-icon",class:{
                      'selected-sort':
                        _vm.selectedSort === _vm.enumSelectedSort.COURIER_NAME_ASC,
                    }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                      'selected-sort':
                        _vm.selectedSort === _vm.enumSelectedSort.COURIER_NAME_DESC,
                    }},[_c('AscendingIcon')],1)])])]}},{key:"header-3",fn:function(ref){
                    var rest = objectWithoutProperties( ref, [] );
                    var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5 items-center cursor-pointer",attrs:{"data-testid":"shuttle-form__courier-phone-number-sort"},on:{"click":function($event){return _vm.onTableSort(_vm.enumSortFields.COURIER_PHONE_NUMBER)}}},[_c('span',{staticClass:"w-32 sort-field"},[_vm._v(_vm._s(data.data.title))]),_c('div',{staticClass:"flex flex-col table-sort"},[_c('div',{staticClass:"ascending-icon",class:{
                      'selected-sort':
                        _vm.selectedSort ===
                        _vm.enumSelectedSort.COURIER_PHONE_NUMBER_ASC,
                    }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                      'selected-sort':
                        _vm.selectedSort ===
                        _vm.enumSelectedSort.COURIER_PHONE_NUMBER_DESC,
                    }},[_c('AscendingIcon')],1)])])]}},{key:"7",fn:function(ref){
                    var rest = objectWithoutProperties( ref, [] );
                    var data = rest;
return [_c('div',{staticClass:"text-sm font-semibold text-bgPrimary cursor-pointer",attrs:{"data-testid":("shuttle-form__delete-courier-" + (data.index))},on:{"click":function () { return _vm.onDeleteShuttleCourier(data.data.value); }}},[_vm._v(" Hapus ")])]}}],null,false,407353284)})],1):_vm._e(),_c('Button',{staticClass:"flex flex-row gap-1 text-sm font-semibold w-52 justify-center",attrs:{"buttonStyle":"outline","routeTo":{
            name: _vm.isEditPage
              ? 'ShuttleManagementEditAddCourier'
              : 'ShuttleManagementCreateAddCourier',
          }}},[_c('PlusIcon'),_vm._v(" Tambah Kurir ")],1)],1)])]),_c('div',{staticClass:"flex flex-row items-end gap-2 justify-end pb-6 pt-2"},[_c('Button',{staticClass:"text-sm font-semibold",attrs:{"buttonStyle":"outline"},on:{"click":function () {
          _vm.$router.go(-1)
        }}},[_vm._v("Kembali")]),_c('Button',{staticClass:"text-sm font-semibold",attrs:{"data-testid":"shuttle-form__submit-button","disabled":_vm.$v.form.$invalid},on:{"click":function () {
          _vm.isModalConfirmVisible = true
        }}},[_vm._v("Simpan")])],1),_c('ModalConfirm',{attrs:{"data-testid":"shuttle-form__modal-confirm","visible":_vm.isModalConfirmVisible,"title":"Simpan Data Shuttle?","description":"Pastikan data yang dibuat udah sesuai ya","actionBtnLabel":"Simpan","cancelBtnLabel":"Cek Ulang"},on:{"cancel":function () {
        _vm.isModalConfirmVisible = false
      },"action":_vm.submit}}),_c('ModalSuccess',{attrs:{"data-testid":"shuttle-form__modal-success","visible":_vm.isModalSuccessVisible,"title":("Data Berhasil " + (_vm.isEditPage ? 'Diubah' : 'Ditambahkan'))},on:{"action":function () {
        _vm.$router.push({
          name: 'ShuttleManagementList',
        })
        _vm.isModalSuccessVisible = false
      }}}),(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }